import React, { useState, useEffect } from 'react';
import { Sun, Moon, DollarSign, Calendar, Clock, Briefcase, Percent, Award, Globe, Calculator, Download } from 'lucide-react';

const experienceLevels = [
  { label: 'Entry Level (0-2 years)', value: 1 },
  { label: 'Mid Level (3-5 years)', value: 1.5 },
  { label: 'Senior Level (6-10 years)', value: 2 },
  { label: 'Expert Level (10+ years)', value: 2.5 },
];

const locations = [
  { label: 'United States', value: 1.5 },
  { label: 'Western Europe', value: 1.3 },
  { label: 'Eastern Europe', value: 0.8 },
  { label: 'Asia', value: 0.7 },
  { label: 'Other', value: 1 },
];

const InputField = ({ icon: Icon, label, ...props }) => (
  <div className="space-y-2">
    <label className="flex items-center text-sm font-medium text-gray-200">
      <Icon size={16} className="mr-2" />
      {label}
    </label>
    <input
      {...props}
      className="w-full px-4 py-3 bg-gray-800/50 border border-gray-700 rounded-xl
        text-gray-100 placeholder-gray-500
        focus:ring-2 focus:ring-indigo-500 focus:border-transparent
        transition-all duration-200"
    />
  </div>
);

const SelectField = ({ icon: Icon, label, options, ...props }) => (
  <div className="space-y-2">
    <label className="flex items-center text-sm font-medium text-gray-200">
      <Icon size={16} className="mr-2" />
      {label}
    </label>
    <select
      {...props}
      className="w-full px-4 py-3 bg-gray-800/50 border border-gray-700 rounded-xl
        text-gray-100 appearance-none
        focus:ring-2 focus:ring-indigo-500 focus:border-transparent
        transition-all duration-200"
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default function FreelanceCalculator() {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [annualSalary, setAnnualSalary] = useState('');
  const [workingDays, setWorkingDays] = useState('240');
  const [hoursPerDay, setHoursPerDay] = useState('8');
  const [expenses, setExpenses] = useState('');
  const [billablePercentage, setBillablePercentage] = useState(70);
  const [experienceLevel, setExperienceLevel] = useState(1);
  const [location, setLocation] = useState(1);
  const [rates, setRates] = useState(null);

  const calculateRates = () => {
    const salary = parseFloat(annualSalary) || 0;
    const days = parseFloat(workingDays) || 240;
    const hours = parseFloat(hoursPerDay) || 8;
    const exp = parseFloat(expenses) || 0;
    
    const totalRequired = (salary + exp);
    const totalHours = days * hours;
    const billableHours = totalHours * (billablePercentage / 100);
    const baseHourlyRate = (totalRequired / billableHours) / (experienceLevel * location);
    const adjustedHourlyRate = baseHourlyRate * experienceLevel * location;
    
    setRates({
      hourly: adjustedHourlyRate,
      daily: adjustedHourlyRate * hours,
      weekly: adjustedHourlyRate * hours * 5,
      monthly: (adjustedHourlyRate * hours * days) / 12,
      yearly: adjustedHourlyRate * hours * days
    });
  };

  const exportData = () => {
    if (!rates) return;

    const reportText = `
Freelance Rate Calculator Results
Generated on ${new Date().toLocaleDateString()}

YOUR PROFESSIONAL RATES
----------------------
Hourly Rate: $${rates.hourly.toFixed(2)}
Daily Rate: $${rates.daily.toFixed(2)}
Weekly Rate: $${rates.weekly.toFixed(2)}
Monthly Rate: $${rates.monthly.toFixed(2)}

PROJECTED ANNUAL INCOME
----------------------
$${rates.yearly.toFixed(2)}

INPUT PARAMETERS
---------------
Annual Target Income: $${annualSalary}
Working Days per Year: ${workingDays}
Hours per Day: ${hoursPerDay}
Business Expenses: $${expenses}
Billable Time Percentage: ${billablePercentage}%
Experience Level: ${experienceLevels.find(level => level.value === experienceLevel)?.label}
Location: ${locations.find(loc => loc.value === location)?.label}
    `.trim();

    const blob = new Blob([reportText], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'freelance-rates.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-gray-100">
      <div className="max-w-4xl mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-indigo-400 to-purple-400 bg-clip-text text-transparent">
            Freelance Rate Calculator
          </h1>
          <p className="text-gray-400">
            Calculate your perfect rates based on experience & location
          </p>
        </div>

        <div className="bg-gray-800/30 backdrop-blur-xl rounded-3xl p-8 border border-gray-700/50 shadow-2xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <InputField
              icon={DollarSign}
              label="Annual Target Income"
              type="number"
              value={annualSalary}
              onChange={(e) => setAnnualSalary(e.target.value)}
              placeholder="Desired Annual Salary ($)"
            />

            <div className="grid grid-cols-2 gap-4">
              <InputField
                icon={Calendar}
                label="Working Days"
                type="number"
                value={workingDays}
                onChange={(e) => setWorkingDays(e.target.value)}
                placeholder="Days per Year"
              />
              <InputField
                icon={Clock}
                label="Daily Hours"
                type="number"
                value={hoursPerDay}
                onChange={(e) => setHoursPerDay(e.target.value)}
                placeholder="Hours per Day"
              />
            </div>

            <InputField
              icon={Briefcase}
              label="Business Expenses"
              type="number"
              value={expenses}
              onChange={(e) => setExpenses(e.target.value)}
              placeholder="Annual Expenses ($)"
            />

            <div className="space-y-2">
              <label className="flex items-center text-sm font-medium text-gray-200">
                <Percent size={16} className="mr-2" />
                Billable Time: {billablePercentage}%
              </label>
              <input
                type="range"
                min="0"
                max="100"
                step="5"
                value={billablePercentage}
                onChange={(e) => setBillablePercentage(Number(e.target.value))}
                className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer accent-indigo-500"
              />
              <div className="text-sm text-gray-400 mt-1">
                Most freelancers bill 60-75% of their time
              </div>
            </div>

            <SelectField
              icon={Award}
              label="Experience Level"
              value={experienceLevel}
              onChange={(e) => setExperienceLevel(Number(e.target.value))}
              options={experienceLevels}
            />

            <SelectField
              icon={Globe}
              label="Location"
              value={location}
              onChange={(e) => setLocation(Number(e.target.value))}
              options={locations}
            />
          </div>

          <button
            onClick={calculateRates}
            className="w-full mt-8 py-4 px-6 flex items-center justify-center gap-2
              bg-gradient-to-r from-indigo-600 to-purple-600
              hover:from-indigo-500 hover:to-purple-500
              text-white font-medium rounded-xl
              transform transition-all duration-200
              hover:scale-[1.02] active:scale-[0.98]
              shadow-lg shadow-indigo-500/25"
          >
            <Calculator size={20} />
            Calculate Your Rates
          </button>

          {rates && (
            <div className="mt-8 space-y-6">
              <div className="bg-gray-800/50 rounded-2xl p-6 border border-gray-700/50">
                <h2 className="text-2xl font-bold text-center mb-6 bg-gradient-to-r from-indigo-400 to-purple-400 bg-clip-text text-transparent">
                  Your Professional Rates
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {Object.entries(rates).slice(0, 4).map(([period, rate]) => (
                    <div key={period} className="bg-gray-900/50 rounded-xl p-4 border border-gray-700/50">
                      <div className="text-gray-400 text-sm mb-1 capitalize">
                        {period} Rate
                      </div>
                      <div className="text-xl font-bold">
                        ${rate.toFixed(2)}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-6 bg-gradient-to-r from-indigo-600 to-purple-600 rounded-xl p-6 text-center">
                  <div className="text-gray-200 text-sm mb-1">
                    Projected Annual Income
                  </div>
                  <div className="text-3xl font-bold">
                    ${rates.yearly.toFixed(2)}
                  </div>
                </div>
              </div>
              <button
                onClick={exportData}
                className="w-full py-4 px-6 flex items-center justify-center gap-2
                  bg-gray-700 hover:bg-gray-600
                  text-white font-medium rounded-xl
                  transform transition-all duration-200
                  hover:scale-[1.02] active:scale-[0.98]"
              >
                <Download size={20} />
                Export Results
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
